<template>
  <div style="height: 100%">
    <SiteHeader></SiteHeader>
    <div class="topbar text-center" v-if="!token">
      <strong class="fs-1">Submit only when you're logged in.</strong>
      <div class="menu">
        <div class="alink" @click="tologin">Login</div>
        <div class="alink" @click="toRegister">Register</div>
      </div>
    </div>
    <div class="topbar text-center" v-else>
      <strong class="fs-1"
        >Pre-sale orders need to be reviewed to be effective.</strong
      >
      <div class="menu">
        <div class="current alink">My Cart</div>
        <div class="alink" @click="toOrders">Orders</div>
        <div class="alink" @click="toAccount">Account</div>
        <div class="alink" @click="toMessages">Messages</div>
        <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="container contentArea mt-5 mb-5">
      <div class="cart-form">
        <div class="jumbotron psrel mb-4">
          <hr class="my-4" />
          <button
            class="btn btn-primary checked-all buttonsize"
            data-name="1"
            @click="toggleAll"
          >
            {{ CheckAll }}
          </button>
          <button
            class="btn btn-secondary clear-all buttonsize"
            @click="removeAll"
          >
            Empty Cart
          </button>
        </div>

        <ul class="list-group bg1">
          <el-empty
            image="svg/empty-cart.svg"
            description="You haven't added any items."
            v-if="!cartdata"></el-empty>

          <li
            class="cartLi mbstyle list-group-item list-group-item-action"
            v-for="(item, index) in cartdata"
          >
            <div class="alert">
              <label v-if="item.mask === 2"
                >(Sold Out)
                <em class="timeem"
                  >Add Date: {{ changeYMDHIS(item.addtime) }}</em
                >
                <em class="timeem" v-if="item.itemType === 'pre-order'"
                  >[Pre-Order]</em
                >
              </label>

              <label v-else>
                <input
                  type="checkbox"
                  class="j-checkbox"
                  v-model="item.checked"
                  @input="toggle(item)"
                  name="items"
                />
                <em class="timeem"
                  >Add Date: {{ changeYMDHIS(item.addtime) }}</em
                >
                <em class="timeem" v-if="item.itemType === 'pre-order'"
                  >[Pre-Order]</em
                >
              </label>
              <span @click="remove(item)" class="badge text-bg-dark badge-pill"
                ><i></i
              ></span>
            </div>

            <a class="thumb" @click="todetail(item.id)" target="_blank"
              ><img :src="item.thumb"
            /></a>

            <div class="cart-c" @click="todetail(item.id)">
              <a class="cart-full" target="_blank">
                <h3 class="mb-3">{{ item.title }}</h3>
                <p>
                  <span class="me-3 pe-3"
                    ><i class="bi-calendar3 text-danger"></i> <em>Delivery Date: </em>{{ changeYMD(item.ship_at) }}</span
                  >
                  <span class="last"
                    ><i class="bi-front text-danger"></i> <em>Series:</em
                    >{{ item.series }}</span
                  >
                </p>
                <p>
                  <span class="me-3 pe-3"
                    ><i class="bi-upc-scan"></i> <em>Code: </em
                    >{{ item.code }}</span
                  >
                  <span class="me-3 pe-3"
                    ><i class="bi-broadcast"></i> <em>Dash: </em
                    >{{ item.dash }}</span
                  >
                  <span class="last"
                    ><i class="bi-vinyl"></i> <em>CasePack: </em
                    >{{ item.casepack }}PC</span
                  >
                </p>
                <p>
                  <span v-if="!token" class="last">Login to see price</span>

                  <span v-if="token" class="me-3 pe-3"
                    ><i class="bi-boxes"></i> <em>Warehouse:</em>
                    {{ item.warehouse }}
                  </span>
                  <span v-if="token" class="last"
                    ><i class="bi-cash-stack text-warning"></i>
                    {{ item.price }} USD/PC
                  </span>
                </p>
              </a>
            </div>
            <div class="cart-r">
              <section>
                <span v-if="token" class="btn btn-outline-danger mb-3">
                  <i class="bi-cart3"></i>
                  <abbr
                    ><b>Amount:</b><b>$</b
                    ><b class="total_price">{{ item.sub_price }}</b> <b>USD</b>
                  </abbr>
                </span>

                <span v-if="token" class="shop-add">
                  <button
                    class="car-reduce btn btn-secondary"
                    :disabled="item.reduceDisabled"
                    @click.stop="reduce(index)"
                  >
                    <i class="bi-dash"></i>
                  </button>
                  <input
                    type="text"
                    class="car-nums btn btn-outline-secondary"
                    @input="handleInput($event, item)"
                    @blur="changeCount(item, $event.target.value)"
                    :value="item.count"
                  />
                  <button
                    class="car-add btn btn-secondary"
                    :disabled="item.plusDisabled"
                    @click.stop="plus(index)"
                  >
                    <i class="bi-plus"></i>
                  </button>
                </span>

                <span class="text-center">
                  Min.Quantity:{{ item.moq }} PC
                </span>
              </section>
            </div>
          </li>
        </ul>

        <hr class="my-4" />
        <a
          v-if="!token"
          class="ajax-btn sub btn btn-primary buttonsize"
          @click="tologin"
          >Login To Checkout</a
        >
        <div v-if="token" class="alert alert-danger" role="alert">
          <span
            >Total Amount:<b class="total_amount">{{ totalPrice }}</b> USD</span
          >
          <span style="margin-left: 20px"
            >Selected <b class="total_items">{{ checkedNumber }}</b> Items</span
          >
        </div>
        <button
          v-if="token"
          @click="confirmOrder"
          class="ajax-btn sub btn btn-primary"
        >
          Proceed To Checkout
        </button>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";

import Foot from "@/components/website/Foot.vue";
import { confirmOrder, cartprice } from "@/api/index.js";

export default {
  name: "PcWebsiteCart",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      checkedNumber: 0,
      CheckAll: "CheckAll",
      count: 0,
      totalPrice: 0,
    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
    cartdata: {
      get() {
        const cartdata = JSON.parse(this.$store.state.user.cartdata);
        if (cartdata != null) {
          return this.countSubPrice(cartdata).sort((a, b) => {
            // 将时间从大到小排序
            return new Date(b.addtime) - new Date(a.addtime);
          });
        }
      },
      set(v) {
        // console.log('-2--', v)
        this.$store.commit("user/setCartData", v);
      },
    },
  },

  watch: {
    cartdata: {
      handler(v) {
        // console.log('---', v)
        this.$store.commit("user/setCartData", v);
      },
      deep: true,
    },
  },
  mounted() {
    //获取价格并计算单价和更新价格
    if (this.cartdata != null) {
      this.getPrice().then((res) => {
        // console.log('-------', this.cartdata.length, this.cartdata.filter(item => item.checked === true).length)
        if (
          this.cartdata.length ===
          this.cartdata.filter((item) => item.checked === true).length
        ) {
          this.CheckAll = "UncheckAll";
        } else {
          this.CheckAll = "CheckAll";
        }
      });
    }
  },
  methods: {
    todetail(id) {
      this.$router.push("/detail/" + id);
    },

    handleInput(event, item) {
      item.count = event.target.value; // 实时更新数据模型
      this.$store.commit("user/setCartData", this.cartdata);
    },

    changeCount(item, value) {
      value = parseInt(value);
      const casepack = item.casepack;

      if (item.max === 0) {
        item.max = item.stock;
      }

      const max = item.max < item.stock ? item.max : item.stock;
      const moq = item.moq;
      // console.log('value', value, 'max', max, 'moq', moq, 'casepack', casepack)

      let newvalue = item.moq;
      if (value < parseInt(moq)) {
        newvalue = moq;
      }
      if (value > max) {
        newvalue = max;
      }
      if (moq < value && value < max) {
        newvalue = Math.floor((value - moq) / casepack) * casepack + moq;
      }

      item.count = newvalue;

      this.$store.commit("user/setCartData", this.cartdata);
    },

    //确认订单
    confirmOrder() {
      //判断是否checked 选中
      let checkedItems = this.cartdata.filter((item) => item.checked === true);
      if (checkedItems.length === 0) {
        this.$message({
          message: "Please select at least one item",
          type: "warning",
        });
        return;
      }

      //提交参数
      const goods_info = checkedItems.map((item) => {
        return {
          id: item.id,
          number: item.count,
        };
      });
      const params = {
        goods_info: JSON.stringify(goods_info),
      };

      //下单具体需要接口替换
      confirmOrder(params).then((res) => {
        // console.log('res',res)
        const orderno = res.data.orderno;

        //删除购物车中选中的数据
        this.cartdata = this.cartdata.filter((item) => item.checked === false);
        this.$store.commit("user/setCartData", this.cartdata);
        //跳转到订单确认页
        this.$router.push("/order/confirm/" + orderno);
      });
    },
    removeAll() {
      this.cartdata.map((item) => {
        this.cartdata = [];
      });
      this.$store.commit("user/setCartData", this.cartdata);
    },

    remove(data) {
      this.cartdata.map((item) => {
        if (item.id === data.id) {
          this.cartdata.splice(item, 1);
        }
      });

      this.$store.commit("user/setCartData", this.cartdata);
      this.countPrice();
    },

    toggleAll() {
      const status = this.CheckAll === "CheckAll" ? true : false;
      this.CheckAll = this.CheckAll === "CheckAll" ? "UncheckAll" : "CheckAll";

      this.cartdata.map((item, index) => {
        item.checked = status;
        return item;
      });
      this.$store.commit("user/setCartData", this.cartdata);

      this.countPrice();
    },
    toggle(data) {
      this.cartdata.map((item) => {
        if (item.id === data.id) {
          item.checked = item.checked === false ? true : false;
        }
        return item;
      });

      if (
        this.cartdata.length ===
        this.cartdata.filter((item) => item.checked === true).length
      ) {
        this.CheckAll = "UncheckAll";
      } else {
        this.CheckAll = "CheckAll";
      }

      this.$store.commit("user/setCartData", this.cartdata);
      this.countPrice();
    },
    ensureFloat(value) {
      if (typeof value === "string") {
        const floatValue = parseFloat(value);
        // 检查parseFloat是否成功转换成了一个非NaN的浮点数
        if (!isNaN(floatValue)) {
          return floatValue;
        }
      } else if (typeof value === "number" || typeof value === "boolean") {
        // 如果是数字或布尔值，直接返回其对应的数字值
        return Number(value);
      }

      throw new Error(
        "Invalid input provided. Expected a string convertible to a float or a number/boolean."
      );
    },

    getPrice() {
      var that = this;

      return new Promise((resolve) => {
        // 检查令牌是否存在
        if (!this.token) {
          // 令牌不存在，返回空的 Promise
          resolve([]);
          return;
        }

        const params = that.cartdata.map((obj) => obj.id);
        cartprice({ ids: JSON.stringify(params) }).then((res) => {
          const priceArr = res.data;
          console.log('priceArr', priceArr)
          const newdata = that.cartdata.map((item) => {
            priceArr.map((resPrice) => {
              if (item.id === resPrice.id) {
                item.price = this.ensureFloat(resPrice.price);
              }
            });
            return item;
          });
          this.cartdata = newdata;
          resolve(newdata);
        });
      });
    },



    /**
     * 计算小计和总价
     */
    countPrice() {
      let total = 0;
      let num = 0;
      this.cartdata.map((item) => {
        if (item.checked) {
          total += item.sub_price;
          //console.log('sub_price:', item.sub_price);
          num++;
        }
        return item;
      });
      this.totalPrice = total;
      //console.log('total:', total);
      this.checkedNumber = num;
    },

    countSubPrice(cartdata) {
      this.checkedNumber = 0;
      this.totalPrice = 0; // 重置totalPrice为零
      const cdata = cartdata.map((item) => {
        if (item.max === 0) {
          item.max = item.stock;
        }

        item.plusDisabled = item.count < item.max ? false : true;
        item.reduceDisabled = item.count === item.moq ? true : false;
        item.sub_price = this.numMulti(item.price, item.count);

        if (item.checked === true) {
          this.checkedNumber++;
          this.totalPrice += item.sub_price; // 将每个商品的sub_price累加到totalPrice中
        }

        return item;
      });

      return cdata;
    },


    /**
     * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
     * @param num1 被乘数
     * @param num2 乘数
     */
    numMulti(num1, num2) {
      var baseNum = 0;
      try {
        baseNum += num1.toString().split(".")[1].length;
      } catch (e) {}
      try {
        baseNum += num2.toString().split(".")[1].length;
      } catch (e) {}
      return (
        (Number(num1.toString().replace(".", "")) *
          Number(num2.toString().replace(".", ""))) /
        Math.pow(10, baseNum)
      );
    },

    plus(index) {
      const itemdata = this.cartdata[index];

      if (this.cartdata[index].max === 0) {
        this.cartdata[index].max = this.cartdata[index].stock;
      }

      if (this.cartdata[index].count < this.cartdata[index].max) {
        itemdata.count = itemdata.count + itemdata.casepack;
    
        itemdata.sub_price = this.numMulti(itemdata.price, itemdata.count); // 更新商品小计
        //console.log('plus:', itemdata.count, itemdata.sub_price);
        
        itemdata.plusDisabled = itemdata.count >= itemdata.max; // 更新加号按钮状态
        itemdata.reduceDisabled = itemdata.count <= itemdata.moq; // 更新减号按钮状态
        this.$set(this.cartdata, index, itemdata);
        this.$store.commit("user/setCartData", this.cartdata);
        this.countPrice(); // 更新总价
      }
    },

    reduce(index) {
      const itemdata = this.cartdata[index];
      if (this.cartdata[index].count > this.cartdata[index].moq) {
        itemdata.count = itemdata.count - itemdata.casepack;
        itemdata.sub_price = this.numMulti(itemdata.price, itemdata.count); // 更新商品小计
        itemdata.plusDisabled = itemdata.count >= itemdata.max; // 更新加号按钮状态
        itemdata.reduceDisabled = itemdata.count <= itemdata.moq; // 更新减号按钮状态
        this.$set(this.cartdata, index, itemdata);
        this.$store.commit("user/setCartData", this.cartdata);
        this.countPrice(); // 更新总价
      }
    },


    changeYMDHIS(timestamp) {
      // 将时间戳转换为日期对象
      var dateObject = new Date(timestamp);

      // 分别获取年、月、日、时、分、秒
      var year = dateObject.getFullYear();
      var month = dateObject.getMonth() + 1; // 注意月份是从0开始的，所以需要+1
      var day = dateObject.getDate();
      var hours = dateObject.getHours();
      var minutes = dateObject.getMinutes();
      var seconds = dateObject.getSeconds();

      // 对于月份和个位数的日期或时间进行补零处理
      month = month.toString().padStart(2, "0");
      day = day.toString().padStart(2, "0");
      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      // console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    },

    changeYMD(timestamp) {
      const dateObject = new Date(timestamp);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      return `${month}/${day}/${year}`;
    },

    toRegister() {
      this.$router.push("/register");
    },

    tologin() {
      this.$router.push("/login");
    },
    toOrders() {
      this.$router.push("/order/list");
    },

    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.contentArea {
  min-height: calc(100vh - 500px);
}

.buttonsize {
  margin: 5px;
  font-size: 1.4em;
  font-weight: bold;
}

.alert {
  font-size: 1rem;
}

.alert .timeem {
  cursor: pointer;
}
</style>
